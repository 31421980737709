import { Item, MiscMarker } from '../classes';
import { IconFileNames } from './icons';
import { MapIds } from './intel';
import { IMisc } from './types';

// TODO : Move as many of these as I can inside the EE Types, too much refactoring for refactoring sake
export enum EggType {
	dieMaschineEE = `Seal the Deal`, //https://callofduty.fandom.com/wiki/Seal_the_Deal
	dieMaschineWW = `D.I.E. Wonder Weapon`,

	firebaseZEE = `Maxis Potential`, //https://callofduty.fandom.com/wiki/Maxis_Potential
	firebaseZWW = `Rai K-84`,
	firebaseZSafe = `Sergei's Safe`,
	firebaseZMonkeyUpgrade = `Monkey bomb upgrade`,
	firebaseZFreeJump = `Free Jump pad`,

	mauerDerTotenEE = `Tin Man Heart`, //https://callofduty.fandom.com/wiki/Tin_Man_Heart
	mauerDerTotenWW = `CRBR-S`,
	mauerDerTotenTargets = `Target Practice`,
	mauerDerTotenKlaus = `Klaus`,

	outbreakEE1 = `Ravenov Implications`, //https://callofduty.fandom.com/wiki/Ravenov_Implications
	outbreakEE2 = `Entrapment`, //https://callofduty.fandom.com/wiki/Entrapment
};

export const WorldEventTypes = {
	furyCrystal: new Item({ title: `Fury Crystal` }),
	escort: new Item({ title: `Escort` }),
	redChallengeChest: new Item({ title: `Red Challenge Chest` }),
	purpleChallengeChest: new Item({ title: `Purple Challenge Chest` }),
	dragonRocket: new Item({ title: `Dragon Rocket` }),
	orda: new Item({ title: `Orda` }),
	horde: new Item({ title: `Horde` }),
	orb: new Item({
		title: `Aetherial orb`, desc: `Damage it and chase it 3 times, you can also shoot it mid-air whilst moving to each location.`,
	}),
	musicRadio: new Item({ title: `Music radio` }),
};

const EETypes = {
	// A required main quest step or interactable
	mainQuest: new Item({
		title: `Main Quest`, icon: IconFileNames.mainQuest,
	}),
	// Required for quests, usually visible on the leaderboard menu
	questCollectible: new Item({
		title: `Quest Collectible`, icon: IconFileNames.objective,
	}),
	// Part of the music easter egg quest
	cassetteTape: new Item({
		title: `Cassette Tape`, icon: IconFileNames.cassette,
	}),
	clue: new Item({ title: `Visual Clue`, icon: IconFileNames.clue }),
	// An initially hidden area, or interactable, that makes available a new area that is not immediately accessible.
	secretArea: new Item({
		title: `Secret Area`, icon: IconFileNames.secretArea,
	}),
	// Can be interacted with, but provides no direct reward
	interactable: new Item({
		title: `Interactable Object`, icon: IconFileNames.interactable,
	}),
	// A bonus interactable or side quest that provides a reward
	bonus: new Item({
		title: `Bonus Item`, icon: IconFileNames.interactable,
	}),
	darkAetherPortal: new Item({
		title: `Dark Aether Portal`, icon: IconFileNames.orb, desc: `Possible spawn location.\nUsed to enter the Dark Aether.\nWill reopen after 25 kills if the objective associated with it is not completed.`,
	}),
	dieMaschinePaPPortal: new Item({
		title: `Dark Aether Portal`, icon: IconFileNames.orb, desc: `The first aether portal to spawn after turning on power, used to craft PaP.`,
	}),
	// Die Maschine specific
	dieMaschineBonusChest: new Item({
		title: "Coffin Dance", icon: IconFileNames.chest,
	}),
	dieMaschineAntenna: new Item({
		title: "Radio Alignment", icon: IconFileNames.interactable,
	}),
	dieMaschineOrdaHand: new Item({
		title: "Orda Hand", icon: IconFileNames.interactable,
	}),
	dieMaschineFloatingBodies: new Item({
		title: "Floating Bodies", icon: IconFileNames.interactable,
	}),
	// Firebase Z specific
	firebaseZBonusChest: new Item({
		title: `Bunny Scare`, icon: IconFileNames.chest,
	}),
	// Maeur Der Toten specific
	mauerDerTotenBonusChest: new Item({
		title: `Hasenbau Club`, icon: IconFileNames.chest,
	}),
	essenceHarvester: new Item({
		title: `Essence Harvester`, icon: IconFileNames.reactor,
	}),
	// Forsaken specific
	forsakenBonusChest: new Item({
		title: "Bonus Chest", icon: IconFileNames.chest,
	}),
	forsakenPizza: new Item({
		title: `Ronald Raygun Pizza`, icon: IconFileNames.pizza,
	}),
	forsakenAetherCrystals: new Item({
		title: `Large Aether Crystal`, icon: IconFileNames.crystal,
	}),
	// D.I.E. Wonder Weapon Specific
	thermophasic: new Item({ title: `D.I.E. Thermophasic Upgrade`, icon: IconFileNames.upgrade, }),
	nova5: new Item({ title: `D.I.E. Nova-5 Upgrade`, icon: IconFileNames.upgrade, }),
	electrobolt: new Item({ title: `D.I.E. Electrobolt Upgrade`, icon: IconFileNames.upgrade, }),
	cryoemitter: new Item({ title: `D.I.E. Cryo-Emitter Upgrade`, icon: IconFileNames.upgrade, }),
	// Outbreak specific
	dementedEcho: new Item({ title: 'Demented Echo', desc: 'Possible location. Starts spawning from round 2 onwards. Destroy it before it touches you to get a reward.', icon: IconFileNames.demented, }),
	aetherialOrb: new Item({ title: `Aetherial Orb`, desc: `Possible location.\nCan be shot for essence, follow it to all locations in order to destroy it at the end to gain loot.`, icon: IconFileNames.orb, }),
	monkey: new Item({ title: 'Stone Monkey', desc: `Used in the first main quest in outbreak to obtain the microfilm only after completing the radio step on the previous map, otherwise just drops minor loot.`, icon: IconFileNames.monkey }),
	projector: new Item({ title: 'Projector', desc: 'The projector for the first main quest in outbreak.\nCan only be interacted with after obtaining the microfilm from a stone monkey statue.', icon: IconFileNames.projector, }), signal: new Item({ title: 'Signal' }),
	redRift: new Item({ title: 'Red Aether Rift', desc: 'Jump through all the rifts to continue the quest.', icon: IconFileNames.redRift, }),
	maxisQuestRadio: new Item({ title: 'Maxis Radio Signal', desc: 'Power on and then match the tone of the 3 surrounding amplifiers. Using subtitles is recommended.\nAfter activation will prompt you to call Maxis using a new menu option in the beacon.', icon: IconFileNames.mainQuest, }),
	maxisAmp: new Item({ title: 'Maxis Radio Amplifier', desc: 'Tune all of the 3 amplifiers to the same tone as the central radio in order to fix the radio and trigger the start of quest.', icon: IconFileNames.objective, }),
	bunny: new Item({ title: 'Mr Peeks', desc: 'Used in the quest, when picked up, provides a aetherium burst ability. Must be brought to the rover to continue the quest.', icon: IconFileNames.objective, }),
	unknownSignal: new Item({ title: 'Unknown Signal Radio', desc: 'Possible location, only one can spawn. Power on and then match the tone of the 3 surrounding amplifiers to be rewarded with an easter egg song and essence. Using subtitles is recommended.', icon: IconFileNames.cassette, }),
	unknownSignalAmp: new Item({ title: 'Unknown Signal Amplifier', desc: 'Tune all of the 3 amplifiers to the same tone as the central radio in order to fix the radio to be rewarded with an easter egg song and essence.', icon: IconFileNames.interactable, }),
	zooMask: new Item({ title: 'Tiger Mask', desc: `Part of the super easter egg, can be interacted with in order to make the next warp be guaranteed to be Zoo.`, icon: IconFileNames.secretArea }),
};

const OutbreakEE2Steps = {
	step2Helicopter: new Item({ title: 'Crashed Helicopter', desc: `The transport chopper that the Omega Eight were using is located in the "Carved Hills", located south of the lone shack, having crashed by unknown means. Nearing it will spawn a horde needs to be eliminated, as one of the corpses is holding a message from Hugo Jager about where the surviving members of the crash went.`, icon: IconFileNames.mainQuest }),
	step3Orb: new Item({ title: 'Red Aetherial Orb', desc: 'The Aetherium Orb can spawn within three places and is visually distinct, having a darker hue of red and will not produce Essence upon being damaged. When damaged, it will flee like the standard variant for a total of three times before it will flee to hover over the Recon Rover to where it will stay above, unwilling to enter it.', icon: IconFileNames.redOrb, }),
};

// Used to generate IDs for all of the below misc markers:
// https://nanoid.jormaechea.com.ar/?alphabet=0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz&length=5
// Please use the existing settings included in the URL and check for duplicate ids when possible (it`s very very unlikely but still possible).

export const StaticEggStore: IMisc = {
	[MapIds.dieMaschine]: [
		new MiscMarker(`3WQ2t`, EETypes.cassetteTape, [162.08070435536573, 256.7927495340165], `1 of 3 needed for the easter egg song\nIn the fridge.`),
		new MiscMarker(`hHYOo`, EETypes.dieMaschineAntenna, [246.73749910857293, 412.077060366895], `1 of 3 satellite dishes that can be shot to readjust them, once all 3 are pointing at the central building, electricity will hit an antenna and produce essence.`),
		new MiscMarker(`4ELTq`, EETypes.dieMaschineAntenna, [417.49920845357474, 232.99036862739175], `1 of 3 satellite dishes that can be shot to readjust them, once all 3 are pointing at the central building, electricity will hit an antenna and produce essence.`),
		new MiscMarker(`V8VJ_`, EETypes.dieMaschineAntenna, [184.41720294197458, 26.708698357113562], `1 of 3 satellite dishes that can be shot to readjust them, once all 3 are pointing at the central building, electricity will hit an antenna and produce essence.`),
		new MiscMarker(`8tI4t`, EETypes.clue, [221.30221321842092, 14.634501196702029], `Orda Bigfoot\nVisual easter egg that starts around round 42. Screen-shake will happen and the Orda can be spotted walking along the treeline.`),
		new MiscMarker(`Ro0Nv`, EETypes.dieMaschineFloatingBodies, [209.88016350392178, 52.82697992955854], `1 of 5 locations, they spawn in one at a time, only in the dark aether. After shooting them all, one will spawn above the lake. Stand directly underneath and shoot it to earn your reward!`),
		new MiscMarker(`3hdzn`, EETypes.dieMaschineFloatingBodies, [399.77174000747004, 244.14631264741922], `1 of 5 locations, they spawn in one at a time, only in the dark aether. After shooting them all, one will spawn above the lake. Stand directly underneath and shoot it to earn your reward!`),
		new MiscMarker(`mdXJT`, EETypes.dieMaschineFloatingBodies, [61.036578161854806, 348.729455345802], `1 of 5 locations, they spawn in one at a time, only in the dark aether. After shooting them all, one will spawn above the lake. Stand directly underneath and shoot it to earn your reward!`),
		new MiscMarker(`4Wsb9`, EETypes.dieMaschineFloatingBodies, [221.30221321842092, 157.05318357436323], `Final Floating Body\nOnly in the dark aether, will only spawn after shooting all 5 others. Stand directly underneath and shoot it to earn your reward!`),
		new MiscMarker(`w_xnt`, EETypes.thermophasic, [223.35366309316512, 223.1585943917737], `Unlocked after following the steps with the fuse in the Dark Aether.`),
		new MiscMarker(`9ySyg`, EETypes.nova5, [315.29654358848376, 295.69795800010473], `Unlocked after following the steps with the cannister in the Dark Aether.`),
		new MiscMarker(`P7XG4`, EETypes.darkAetherPortal, [260.11042243500134, 282.13590175409416], `If yours spawns here, the first aetherscope part will be the antenna under the stairs.`),
		new MiscMarker(`HTstG`, EETypes.darkAetherPortal, [244.00586459413896, 75.84893284213877], `If yours spawns here, the first aetherscope part will be on the crashed plane engine.`),
		new MiscMarker(`XW3JZ`, EETypes.mainQuest, [174.74870507815817, 257.415635207116], `Crack in the wall where you can remotely activate the D.I.E Wonder Weapon after acquiring the D.I.E remote.`),
		new MiscMarker(`wgKEY`, EETypes.questCollectible, [196.29722988085663, 307.495182630813], `1 of 3 Aetherscope Parts, one will spawn randomly when entering the Dark Aether, dogs spawn after picking it up.`),
		new MiscMarker(`N29aN`, EETypes.questCollectible, [356.6938648779643, 343.9768501463755], `1 of 3 Aetherscope Parts, one will spawn randomly when entering the Dark Aether, dogs spawn after picking it up.`),
		new MiscMarker(`yLQtN`, EETypes.questCollectible, [133.03685434894203, 282.6545483161539], `Cannister used in nova 5 steps, can only be retrieved with the alt fire of the D.I.E.`),
		new MiscMarker(`3eput`, EETypes.questCollectible, [314.69833497058926, 344.089550203993], `Box containing a fuse used in the Thermophasic upgrade steps, hidden under the plane nose, inaccessible unless in the Dark Aether.`),
		new MiscMarker(`G7SE2`, EETypes.questCollectible, [242.97643511037063, 255.9881891661138], `Crate containing the vial used in the Cryo upgrade steps. Shoot the crate with the D.I.E to knock it down.`),
		new MiscMarker(`O0QxP`, EETypes.questCollectible, [256.63368476295705, 206.02147315077295], `Tree fungus used in the Cryo upgrade steps\nHave a Megaton shoot the fungus to activate it\nInteract whilst holding the vial to place it underneath the fungus and fill the vial.`),
		new MiscMarker(`mlZBU`, EETypes.darkAetherPortal, [243.40377385527123, 207.31551716379568], `Unlocked after completing the 3 ghostly dialogue steps.\nUsed specifically for the Thermophasic upgrade chest.`),
		new MiscMarker(`nS4PT`, EETypes.questCollectible, [258.3778460127693, 335.23982709555946], `1 of 3 Crystals used in the Electrobolt upgrade steps, use the alt fire of the D.I.E to collect the energy, then run down to the upgrade box and shoot it to progress.`),
		new MiscMarker(`Vc_0w`, EETypes.questCollectible, [377.14480548250435, 224.28934027831147], `1 of 3 Crystals used in the Electrobolt upgrade steps, use the alt fire of the D.I.E to collect the energy, then run down to the upgrade box and shoot it to progress.`),
		new MiscMarker(`nhHde`, EETypes.questCollectible, [258.5949702531161, 173.9165165178388], `1 of 3 Crystals used in the Electrobolt upgrade steps, use the alt fire of the D.I.E to collect the energy, then run down to the upgrade box and shoot it to progress.`),
		new MiscMarker(`pfJ2L`, EETypes.mainQuest, [160.42537161357052, 375.00631403381794], `Broken tank that needs to be interacted with 3 times whilst holding the wrench\nShoot the zombie that pops out, then throw a lethal explosive into the now open hatch. This causes the gun to fire, dropping the Decontamination Agent from the tree it shoots.`),
		new MiscMarker(`vm_wB`, EETypes.darkAetherPortal, [228.68530832965382, 267.7869413601128], `Final portal, used to find the last ghostly dialogue before the boss fight.`),
		new MiscMarker(`TbPJZ`, EETypes.mainQuest, [257.59332755993654, 349.50282422284494], `Final ghostly dialogue anomaly before the boss fight\nInteract with it and Orlov will talk about his family and place the family photo on the table.\nPicking up the photo when out of the dark aether will start the boss fight immediately.`),
	],
	[MapIds.dieMaschineUnderground]: [
		new MiscMarker(`se3Jv`, EETypes.cassetteTape, [349.60922508562146, 350.0557392855062], `1 of 3 needed for the easter egg song\nIn Vogel's office on the bottom shelf by the zombie spawn window.`),
		new MiscMarker(`AJI8t`, EETypes.cassetteTape, [149.7210717465117, 329.38635784232576], `1 of 3 needed for the easter egg song\nOn the very top corner of a bloodied beige monitoring device. It's at eye level, easily missed.`),
		new MiscMarker(`4Edp9`, EETypes.dieMaschineOrdaHand, [345.24724776945624, 316.846711383994], `Down the inaccessible hallway. Can only be activated in the dark aether. You need to shoot 4 buttons to turn on 4 green lights, when activated there will be a large green light from the closest left door. Once this is activated, shoot the button of the door with the large green light to trigger the Orda Hand. After 15 zombies are caught you are rewarded with a legendary weapon upgrade.`),
		new MiscMarker(`teuED`, EETypes.dieMaschineFloatingBodies, [113.86355809141334, 264.13489964779274], `1 of 5 locations, they spawn in one at a time, only in the dark aether. After shooting them all, one will spawn above the lake. Stand directly underneath and shoot it to earn your reward!`),
		new MiscMarker(`2TA2d`, EETypes.dieMaschineFloatingBodies, [324.45759970249134, 279.48327895165096], `1 of 5 locations, they spawn in one at a time, only in the dark aether. After shooting them all, one will spawn above the lake. Stand directly underneath and shoot it to earn your reward!`),
		new MiscMarker(`1m5tF`, EETypes.dieMaschineBonusChest, [147.39538299834174, 388.52809723485626], `Downstairs, hidden behind the top left of the zombie spawn window\n1 of 5 blue orbs that you can shoot for a bonus chest.`),
		new MiscMarker(`PxlsM`, EETypes.dieMaschineBonusChest, [76.10682851697437, 358.63289696847636], `1 of 5 blue orbs that you can shoot for a bonus chest.`),
		new MiscMarker(`ONsCv`, EETypes.dieMaschineBonusChest, [201.05343475851072, 301.68965836584806], `High up on the wall\n1 of 5 blue orbs that you can shoot for a bonus chest.`),
		new MiscMarker(`RFgwR`, EETypes.dieMaschineBonusChest, [221.09307449751262, 347.24424924795073], `1 of 5 blue orbs that you can shoot for a bonus chest.`),
		new MiscMarker(`7TACp`, EETypes.dieMaschineBonusChest, [134.79653471600014, 401.6474904867542], `Hidden behind the grating\n1 of 5 blue orbs that you can shoot for a bonus chest.`),
		new MiscMarker(`UTXXu`, EETypes.secretArea, [293.1716173546175, 370.9160154554998], `Only accessible after using the Aether Tunnel above ground. 1 of 2 places the PaP part can spawn.`),
		new MiscMarker(`6WnTv`, EETypes.secretArea, [206.2697306892794, 176.17280788708192], `Only accessible after using the Aether Tunnel above ground. 1 of 2 places the PaP part can spawn.`),
		new MiscMarker(`VpIJu`, EETypes.cryoemitter, [297.2665148522491, 349.81279151873895], `Unlocked after pouring the liquid gathered from the fungus near the lake, onto the crate.`),
		new MiscMarker(`VNWWl`, EETypes.electrobolt, [113.39539786187888, 353.078013760189], `Unlocked after gathering the energy from 3 crystals and shooting it at the box. The bulbs around the box indicate the progress.`),
		new MiscMarker(`5M9A0`, EETypes.mainQuest, [211.50810943751497, 233.52599646325353], `D.I.E upgrade chest, requires the fuse and can only be interacted with when in the Dark Aether. Re-appears as open, above ground, on the back of the truck.`),
		new MiscMarker(`5oG2A`, EETypes.interactable, [198.7906869627428, 178.82125886330365], `Plaguehound Gas condenser\nInteract whilst holding the canister to place it, then kill a Plaguehound near it to fill it with gas.`),
		new MiscMarker(`af5QE`, EETypes.questCollectible, [326.5807091917049, 308.47697244514126], `Next to speed cola, in the corner.\n1 of 3 Orbs that spawn in the Dark Aether after acquiring the Aetherscope. Interact with it to trigger the ghostly figure dialogue.`),
		new MiscMarker(`xpxgz`, EETypes.questCollectible, [235.5479859749598, 273.1385305408606], `1 of 3 Orbs that spawn in the Dark Aether after acquiring the Aetherscope. Interact with it to trigger the ghostly figure dialogue.`),
		new MiscMarker(`rj9BH`, EETypes.questCollectible, [181.93046504375246, 348.6110192429405], `1 of 3 Orbs that spawn in the Dark Aether after acquiring the Aetherscope. Interact with it to trigger the ghostly figure dialogue.`),
		new MiscMarker(`Kgdjc`, EETypes.questCollectible, [215.23791437285269, 305.50159134986285], `1 of 3 Aetherscope Parts, one will spawn randomly when entering the Dark Aether, dogs spawn after picking it up.`),
		new MiscMarker(`JFz4Z`, EETypes.mainQuest, [339.1999615318702, 326.4211594207544], `Vogel's Computer\n After activating all 3 ghostly dialogues, interact with it 3 times to insert the password.`),
		new MiscMarker(`LHGAv`, EETypes.dieMaschinePaPPortal, [172.09764607654128, 361.8028852234136], `The first aether portal to spawn after turning on power, used to craft PaP.`),
		new MiscMarker(`mR1Ye`, EETypes.darkAetherPortal, [273.33595868320293, 353.86231995501794], `An aether portal that appears after shooting all 4 canisters of the containment device with the corresponding D.I.E upgrade.`),
		new MiscMarker(`270nl`, EETypes.darkAetherPortal, [170.1085037631566, 387.66173529741445], `Unlocked after completing the 3 ghostly dialogue steps.\nUsed specifically for the Electrobolt upgrade chest.`),
		new MiscMarker(`mSMsk`, EETypes.darkAetherPortal, [121.83927074468262, 403.6735944951819], `If yours spawns here, the first aetherscope part will be the one in the particle accelerator room.`),
		new MiscMarker(`sHpo9`, EETypes.darkAetherPortal, [311.82894335858725, 299.6633680932173], `Spawns after building the aetherscope. Used to get the diary and trigger the first of the ghostly dialogues.`),
		new MiscMarker(`fwG9Z`, EETypes.mainQuest, [329.39656431710233, 325.66999226629395], `Ghostly dialogue that appears after entering the portal that appears after shooting after shooting all 4 canisters of the containment device\nSpawns the Dark Aether Wrench.`),
		new MiscMarker(`UyMmA`, EETypes.mainQuest, [310.66531882982684, 339.46395788705297], `Containment device on the roof with four canisters pointing down. Shoot each one with the corresponding elemental version of the D.I.E\nAfter placing the decontamination agent, this will allow you to trap both halves of a Megaton. Furthering the main quest.`),
	],
	[MapIds.firebaseZSpawn]: [
		new MiscMarker(`Xst99`, EETypes.cassetteTape, [361.3527921527954, 188.83980649518872], `1 of 3 needed for the easter egg song\nUp the stairs on the book shelf.`),
		new MiscMarker(`ALMtJ`, EETypes.cassetteTape, [158.88932890084, 205.2850129398853], `1 of 3 needed for the easter egg song\nInside a pigeon hole next to the locker containing one of the compounds for the main quest.`),
		new MiscMarker(`tdq5M`, EETypes.firebaseZBonusChest, [324.0469269977337, 242.0591502874637], `Mr Peeks\nStare at the bunny in outside of the map bounds in order to be teleported to the Dark Aether and obtain a bonus chest.`),
		new MiscMarker(`sDGIO`, EETypes.bonus, [370.180211499256, 197.93612737161737], `Dart Board / Aetherium Converter\nUsing a code found in the weapon lab PC, it can be shot in order (then the bullseye) to drop 1 of 3 parts used to create a free RAI K-84.`),
		new MiscMarker(`sdUX5`, EETypes.mainQuest, [382.9324256232408, 267.11524328413856], `Sergei Ravenov\nMust be interacted with to start the main quest.`),
		new MiscMarker(`xkABv`, EETypes.questCollectible, [164.40213154389374, 204.72718267730167], `Compound S16\n1 of 3 compounds required as part of the truth serum in the main quest.`),
	],
	[MapIds.firebaseZ]: [
		new MiscMarker(`_J2DT`, EETypes.cassetteTape, [254.14859342124302, 233.1454721757814], `1 of 3 needed for the easter egg song\nOn the floor resting against a filing cabinet in the motor pool office.`),
		new MiscMarker(`Spi3p`, EETypes.bonus, [343.34375, 447.84375], `Dimitri Kuhlklay\nBurnt body on the floor that must be interacted with in order to gain access to the PC in the weapons lab, to progress the free RAI K-84 quest. (Note: the blueprint in the weapon lab must be picked up before you can interact with the body.)`),
		new MiscMarker(`SWJb1`, EETypes.bonus, [158.77033215216278, 275.97284112275537], `Kuhlklay's Desk\nUsed in the free RAI K-84 side quest. There is a PC, which must be accessed using Kuhlklay's eye, found on a body in scorched defense, against a tank\nOnce activated it will display graphics of a dart board used to obtain a RAI K-84 part back in the spawn area. A drawer will also open, allowing you to pick up a key, used to unlock lockers in the barracks.`),
		new MiscMarker(`mwfRX`, EETypes.bonus, [271.687227595983, 315.28552532065964], `Lockers that will spawn Mimics when opened with the key obtained from the weapons lab\nOne of the mimics will drop the Barrel Stabiliser, 1 of 3 parts used to create a free RAI K-84.`),
		new MiscMarker(`50_41`, EETypes.bonus, [315.00241949127866, 323.7787002020902], `Lockers that will spawn Mimics when opened with the key obtained from the weapons lab\nOne of the mimics will drop the Barrel Stabiliser, 1 of 3 parts used to create a free RAI K-84.`),
		new MiscMarker(`vHAbq`, EETypes.bonus, [166.0783558425093, 265.6534721947788], `Computer\nUsed as a charging station to charge up the Overcharged Power Cell that is 1 of the 3 parts in the RAI K-84 side quest. You may need to wait a couple of rounds after placing the magazine in order to fully charge it.`),
		new MiscMarker(`4HGj2`, EETypes.mainQuest, [417.51780307656065, 318.2097920532709], `William Peck\nInteract with after activating power across the map, then head back to Ravenov to acquire his ID card.`),
		new MiscMarker(`dpWeL`, EETypes.questCollectible, [282.97038041254837, 215.9674819668458], `Compound P65\n1 of 3 compounds required as part of the truth serum in the main quest.`),
		new MiscMarker(`gafr1`, EETypes.questCollectible, [388.66573427994837, 281.72880422271794], `Compound 36\n1 of 3 compounds required as part of the truth serum in the main quest.`),
		new MiscMarker(`5PdCL`, EETypes.mainQuest, [284.4973798332315, 367.64687177418114], `Chemical Mixer\nFound in the field hospital, can be interacted with after obtaining all 3 compounds to drop the "Agent Delivery System" which will need to be placed on the air conditioning unit of Dr Peck's shelter.`),
		new MiscMarker(`eu2st`, EETypes.mainQuest, [395.8771012671762, 317.7078936762941], `Dr Peck's Air Conditioner\nUsed with the "Agent Delivery System" to deliver the truth serum to Dr Peck. Interact with him afterwards to progress the quest.`),
		new MiscMarker(`_56GU`, EETypes.mainQuest, [170.33779197818103, 352.0203144700901], `Memory Transference Station\nUnlocked after drugging Dr Peck with truth serum. Provides essence traps that are used to catch mimics\nYou can bring the captured mimics back in order to get some dialog from different characters containing codes, the ones we need for the quest are Sokolov, Brahms and Zabim. Once all 3 codes are obtained, the machine will spit out a floppy disk.`),
		new MiscMarker(`LX_RL`, EETypes.mainQuest, [401.70612515057087, 276.58454518563894], `Quantum Main Frame\nCan be interacted with after obtaining the floppy disk from the mimic step. Opens an anomaly in the large spherical chamber outside the window in front of you.`),
		new MiscMarker(`q_skj`, EETypes.secretArea, [383.4063466479532, 299.8771916373098], `OPC (Omega Portal Chamber)\nUsed as part of the main quest, after the mimic step, will open an anomaly and later after the satellite alignment step be must be interacted with in order to teleport to the boss fight.`),
		new MiscMarker(`8YQJG`, EETypes.mainQuest, [408.23933867814446, 321.5496210454767], `Aethermeter\nDrops from a locker next to Dr Peck's shelter after unlocking the Quantum Main Frame and talking to Peck who provides the code.`),
		new MiscMarker(`bWebw`, EETypes.questCollectible, [407.5156407861167, 391.44907135137566], `Shovel\n. Required to dig up Containers after acquiring the Aethermeter.`),
		new MiscMarker(`WkqxY`, EETypes.questCollectible, [240.21203547901894, 62.83786897191934], `Aether Crystal Container 1 of 3\n Can be dug up after acquiring the Shovel and Aethermeter. Triggers a lockdown event that will trap you in a yellow bubble, charging the crystal. Once complete, it can be picked up.`),
		new MiscMarker(`N7CSE`, EETypes.questCollectible, [376.6346016707385, 261.46768955182176], `Aether Crystal Container 1 of 3\n Can be dug up after acquiring the Shovel and Aethermeter. Spawns lots of identical containers in the vicinity, to progress you need to pick up the container that ONLY has a crystal and WHITE smoke. Any black smoke inside a container indicates it is the wrong one.`),
		new MiscMarker(`27K0v`, EETypes.questCollectible, [237.0421966518259, 369.7858267768484], `Aether Crystal Container 1 of 3\n Can be dug up after acquiring the Shovel and Aethermeter\nRequires the RAI K-84\nAfter being dug up, it will immediately teleport away from you, spawning back in a random location in the immediate vicinity. Once spotted, keep your distance and fire an alt fire grenade from the RAI K-84 at the container to keep it in place, then it can be collected.`),
		new MiscMarker(`dMdFW`, EETypes.mainQuest, [389.48725462947607, 253.659943176447], `Computer\n Used after placing all 3 Crystals in the reactors. Will display a world map to realign a satellite, each will have a flag in the bottom right corner. When you see a location with a question mark, align with that one to activate the OPC and allow you to begin the boss fight.`),
	],
	[MapIds.mauerDerToten]: [
		new MiscMarker(`_n0MB`, EETypes.mauerDerTotenBonusChest, [193.68010197590363, 264.8048544502154], `1 of 6 Mr Peeks pieces used to access the bonus chest room.`),
		new MiscMarker(`2wAit`, EETypes.clue, [384.32068891654967, 272.1759937481422], `#0 Blacklight locker`),
		new MiscMarker(`G107e`, EETypes.clue, [181.79736917081456, 459.6975460903231], `#2 Blacklight numbers`),
		new MiscMarker(`I_o9o`, EETypes.mainQuest, [341.84342386218685, 203.37097788580076], `Klaus\nUsed at various points in the quest, must be activated with 2 parts, his hands, found in the hotel room and his battery, dropped by killing the first Krasny Soldat.\nAfter this he can be called in at various locations around the map. He can also be upgraded 2 times, once with a hacking helm to finish the main quest, and a second, optional time, to upgrade his firepower.`),
		new MiscMarker(`h8fAY`, EETypes.questCollectible, [258.4350330544544, 328.8458598698313], `Klaus Part, Electronic Chips, box with green light, shoot with CRBR-S.`),
		new MiscMarker(`GYRE6`, EETypes.interactable, [359.9483781264314, 284.16977220507744], `Train Line Controls, used to switch train tracks during the main quest.`),
		new MiscMarker(`drKer`, EETypes.interactable, [364.2365012890661, 214.16776152079905], `Klaus Part Box, use blacklight to check if it contains a disk, order Klaus to open if it does.`),
		new MiscMarker(`eQXff`, EETypes.interactable, [206.05222014011366, 330.2612687292476], `Klaus Part Box, use blacklight to check if it contains a disk, order Klaus to open if it does.`),
		new MiscMarker(`v9aLr`, EETypes.questCollectible, [168.93078742529096, 137.35493930841415], `Essence Canister Pickup and drop off. (Wait a round for the forcefield to disappear)`),
		new MiscMarker(`jOSoP`, EETypes.essenceHarvester, [151.62276338235262, 217.9029453522394], `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.`),
		new MiscMarker(`dgfYy`, EETypes.essenceHarvester, [258.13627287487003, 336.4144510859678], `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.`),
		new MiscMarker(`CWAYy`, EETypes.secretArea, [310.84582665268846, 317.22578660679153], `Train Carriage, use Klaus to stop the train, contains the Keycard and Warhead needed for the main quest.`),
		new MiscMarker(`4QpYx`, EETypes.secretArea, [168.43591618416733, 160.56583724877993],
			"Entrance to Valentina`s Lab. Klaus can knock the wall down. Then use the CRBR-S beam mod to open the metal door."
		),
		new MiscMarker(`xLLmm`, EETypes.mainQuest, [153.92452665732722, 149.36476379238186], `Endstation Lure Machine\nWhen shot, after placing the essence canisters, will provide endstation lures which are used at the essence charging stations to spawn tempests. When killed, the tempests charge the canister.`),
		new MiscMarker(`HxZ4L`, EETypes.mainQuest, [349.7773651391249, 186.89792418690953], `1 of 3 Computers used during main quest to spawn Megatons, requires keycard from the train. Player will be denied if they have not yet upgraded Klaus.`),
		new MiscMarker(`6Z0wX`, EETypes.mainQuest, [168.0886289452726, 81.37624099731451], `Bossfight initial spawn location.`),
		new MiscMarker(`BcQMe`, EETypes.mainQuest, [167.82182159774044, 39.754294782294636], `Klaus escort location.\nKlaus will still need to be escorted whilst carrying the warhead in order to successfully finish the bossfight.`),
	],
	[MapIds.mauerDerTotenStreets]: [
		new MiscMarker(`ook1f`, EETypes.cassetteTape, [364.594308702165, 411.59576707869667], `1 of 3 needed for the easter egg song.`),
		new MiscMarker(`YMZPe`, EETypes.cassetteTape, [175.42748080394747, 324.57693567676864], `1 of 3 needed for the easter egg song.`),
		new MiscMarker(`00CEF`, EETypes.cassetteTape, [185.86657193523203, 70.19853360643957], `1 of 3 needed for the easter egg song.`),
		new MiscMarker(`pufCN`, EETypes.mauerDerTotenBonusChest, [310.47106934268305, 281.8460771337832], `1 of 6 Mr Peeks pieces used to access the bonus chest room.`),
		new MiscMarker(`9z8Uu`, EETypes.mauerDerTotenBonusChest, [315.8911862106404, 75.03474289078483], `1 of 6 Mr Peeks pieces used to access the bonus chest room.`),
		new MiscMarker(`1uxx8`, EETypes.mauerDerTotenBonusChest, [317.22176766835554, 386.3680282265149], `1 of 6 Mr Peeks pieces used to access the bonus chest room.`),
		new MiscMarker(`IC0Xq`, EETypes.mauerDerTotenBonusChest, [172.9720302577955, 466.15217502439106], `1 of 6 Mr Peeks pieces used to access the bonus chest room.`),
		new MiscMarker(`r79fA`, EETypes.mauerDerTotenBonusChest, [152.2094092404965, 343.9680542153249], `1 of 6 Mr Peeks pieces used to access the bonus chest room.`),
		new MiscMarker(`PwmRS`, EETypes.clue, [110.80096229476811, 298.4347086625506], `#1 Blacklight numbers`),
		new MiscMarker(`muHxa`, EETypes.clue, [302.33874571909564, 311.0221039512368], `#3 Blacklight numbers`),
		new MiscMarker(`0ZFbr`, EETypes.secretArea, [118.31471157271051, 467.80515920170296], `Hotel Room 305 & Klaus Part\nUse a brain rot zombie to open door and get Klaus hands and the safe for CRBR-S.`),
		new MiscMarker(`p3Ots`, EETypes.questCollectible, [267.02354016528875, 171.06511860475285], `Klaus Part, battery, obtained from killing the first Krasny Soldat.`),
		new MiscMarker(`RC0It`, EETypes.interactable, [131.97446059908233, 383.233529816566], `Klaus Part Box, use blacklight to check if it contains a disk, order Klaus to open if it does.`),
		new MiscMarker(`p0jy0`, EETypes.interactable, [296.2174019490392, 91.91078506007518], `Klaus Part Box, use blacklight to check if it contains a disk, order Klaus to open if it does.`),
		new MiscMarker(`1Zm1y`, EETypes.interactable, [202.13121369227582, 53.47866191858418], `Klaus Part Box, use blacklight to check if it contains a disk, order Klaus to open if it does.`),
		new MiscMarker(`1TKX2`, EETypes.interactable, [298.34930940180675, 420.2199816609127], `Klaus Part Box, use blacklight to check if it contains a disk, order Klaus to open if it does.`),
		new MiscMarker(`eOTNC`, EETypes.questCollectible, [373.50680516628154, 388.36535534693917], `Klaus Part, transistor, shoot radios in the room with CRBR-S until one of them drops the part.`),
		new MiscMarker(`DwMIE`, EETypes.questCollectible, [333.76018343302235, 467.30140318168293], `Klaus Part, antenna, shoot pylon with CRBR-S.`),
		new MiscMarker(`5pm__`, EETypes.questCollectible, [197.11229814842187, 196.7896920958058], `Klaus Part, microwave dish, dig piles of debris to find.`),
		new MiscMarker(`3S1gY`, EETypes.questCollectible, [301.6366591960302, 150.97963262432313], `Klaus Part, microwave dish, dig piles of debris to find.`),
		new MiscMarker(`WzV96`, EETypes.mainQuest, [144.5504050965535, 289.50012070442347], `Klaus Upgrade Station`),
		new MiscMarker(`aqK7n`, EETypes.mainQuest, [290.44881000922993, 44.59962748588934], `1 of 3 Computers used during main quest to spawn Megatons, requires keycard from the train. Player will be denied if they have not yet upgraded Klaus.`),
		new MiscMarker(`zq5i8`, EETypes.mainQuest, [377.5561578030767, 339.7506667046973], `1 of 3 Computers used during main quest to spawn Megatons, requires keycard from the train. Player will be denied if they have not yet upgraded Klaus.`),
		new MiscMarker(`YqN75`, EETypes.essenceHarvester, [181.1322876506002, 304.5979694369277], `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.`),
		new MiscMarker(`vgkPi`, EETypes.essenceHarvester, [173.4707721095559, 358.04367363412337], `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.`),
		new MiscMarker(`frfHo`, EETypes.essenceHarvester, [357.6295162672377, 389.7021367473598], `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.`),
		new MiscMarker(`BWQZ0`, EETypes.essenceHarvester, [365.3697268917473, 244.2640308995389], `Possible spawn location of essence harvester.\nUnder the roof in the destroyed penthouse. Used in the quest to charge soul canisters.`),
		new MiscMarker(`rsNWp`, EETypes.essenceHarvester, [276.8902900069504, 110.18445130029662], `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.`),
		new MiscMarker(`suL35`, EETypes.mainQuest, [199.8542173319167, 303.14278836890117], `Zipline used to place one of the uranium devices.`),
		new MiscMarker(`0vSdg`, EETypes.mainQuest, [292.27782488970274, 292.907055944363], `Zipline used to place one of the uranium devices.`),
		new MiscMarker(`guuWl`, EETypes.mainQuest, [251.03046990519945, 172.27978216881027], `Spawn location for the first HVT Megaton which drops the 2 pieces of uranium needed to craft the uranium devices. Can be taken to any workbench to craft.`),
		new MiscMarker(`pOuZZ`, EETypes.mainQuest, [327.83864123291437, 440.6007233273124], `Spawn location for the second HVT Megaton which drops the 2 pieces of uranium needed to craft the uranium devices. Can be taken to any workbench to craft.`),
		new MiscMarker(`yg8rq`, EETypes.mainQuest, [244.90656540916956, 296.09692296650684], `1 of 2 Cleansed Rocks\nUsed in the main quest to arm the warhead placed in the lab. They both require the same steps, with different Megaton spawn locations.`),
	],
	[MapIds.forsaken]: [
		new MiscMarker(`VzBDN`, EETypes.cassetteTape, [160.83297381918277, 202.37192487696], `Bubby Defense\nActivate him by placing the aether bunny inside his back panel. Survive the timer by defending him to obtain a gold reward chest.`),
		new MiscMarker(`aIGFd`, EETypes.bonus, [130.6702681516008, 187.2383874312874], `PHD Slide Spot\nWhen you cause a PHD explosion at this spot in order to obtain a powerup, or the Aether Bunny, used in the Bubby easter egg.`),
		new MiscMarker(`yjPIh`, EETypes.bonus, [52.55243726963396, 147.19548401911365], `PHD Slide Spot\nWhen you cause a PHD explosion at this spot in order to obtain a powerup, or the Aether Bunny, used in the Bubby easter egg.`),
		new MiscMarker(`JcFu8`, EETypes.bonus, [145.65459465472622, 153.68343923758698], `PHD Slide Spot\nWhen you cause a PHD explosion at this spot in order to obtain a powerup, or the Aether Bunny, used in the Bubby easter egg.`),
		new MiscMarker(`u2RLm`, EETypes.bonus, [133.8172435222692, 112.61549892471038], `Pistol Blueprint\nWhen interacted with, it starts a small shooting gallery in the store front, when completed successfully it upgrades the held weapons rarity by 1 level.`),
		new MiscMarker(`UKMsS`, EETypes.bonus, [399.518718750346, 162.32261744868782], `SMG Blueprint\nWhen interacted with, it starts run and gun shooting gallery throughout the pizza store and up the stairs on the left side, when completed successfully it upgrades the held weapons rarity by 1 level.`),
		new MiscMarker(`fiK5X`, EETypes.bonus, [78.67637077654524, 112.73252751643948], `Sniper Blueprint\nWhen interacted with, it starts a shooting gallery across the skyline of the stores, when completed successfully it upgrades the held weapons rarity by 1 level.`),
		new MiscMarker(`j21QQ`, EETypes.bonus, [89.5625, 101.65625], `Arcade Token Dispenser\nDispenses tokens for 10k essence, or if knifed for the first time.`),
		new MiscMarker(`vHgbN`, EETypes.bonus, [126.06004598884398, 220.60508048047697], `Anytown Grand Prix\nWhen impacted by a PHD explosion, activates and allows you to race ARC-XDs around the map with friends.`),
		new MiscMarker(`E5bOF`, EETypes.forsakenBonusChest, [97.79068103184147, 71.92250393932072], `World At War\nUses an arcade token, teleports you to Nacht, fight through the waves in the time limit to earn the maximum reward.`),
		new MiscMarker(`SsF1e`, EETypes.forsakenBonusChest, [112.51909400070444, 83.36132213341226], `Der Eisendrache\nUses an arcade token, teleports you to the top of the pyramid, starting a dragon trial from outbreak, will reward you with a loot chest.`),
		new MiscMarker(`YCmuv`, EETypes.bonus, [98.43470550590929, 90.45351316759233], `Enduro\nUses an arcade token, drive around in a room with an ARC-XD to collect as much essence as possible.`),
		new MiscMarker(`VMpbM`, EETypes.mainQuest, [105.26703279268563, 77.52976775995383], `Activision Grand Prix\nCost: 2000 Essence\nActivated after killing a zombie with deadwire next to it. You will need to drive the ARC-XD behind the loose ventilation shaft in the TV store.`),
		new MiscMarker(`UjJ2J`, EETypes.secretArea, [425.378151687563, 169.59318438272373], `Ronald Raygun\nSecret room that can be teleported into using the upgraded aether shroud ability whilst facing the door.\nDeliver pizzas to 3 locations around the map and interact with him to receive a reward each time.`),
		new MiscMarker(`SfL7b`, EETypes.forsakenPizza, [86.25331875878506, 102.71430028850807], `Pizza Delivery\nInteractable when holding a pizza from Ronald Raygun.`),
		new MiscMarker(`dquTj`, EETypes.forsakenPizza, [162.7646126643831, 138.09457142299507], `Pizza Delivery\nInteractable when holding a pizza from Ronald Raygun.`),
		new MiscMarker(`m8uSv`, EETypes.forsakenPizza, [167.45318425512843, 244.97965375670142], `Pizza Delivery\nInteractable when holding a pizza from Ronald Raygun.`),
		new MiscMarker(`5m8bE`, EETypes.forsakenPizza, [105.75984994618577, 219.02958149971576], `Pizza Delivery\nInteractable when holding a pizza from Ronald Raygun.`),
		new MiscMarker(`1QoYt`, EETypes.questCollectible, [76.34043931284971, 93.08541305858377], `Circuit Board\n Found somewhere on the side in the backroom between the video store and the arcade. Used to repair the teleporter and get to the basement.`),
		new MiscMarker(`aWlqt`, EETypes.questCollectible, [171.2979612374162, 151.22479395215734], `Focusing Lens\nFound in the cinema entrance. Used to repair the teleporter and get to the basement.`),
		new MiscMarker(`rhCLH`, EETypes.questCollectible, [118.87891298990084, 175.87422735426276], `Cathode-Ray Tube\n Found in the TV Store. Used to repair the teleporter and get to the basement.`),
		new MiscMarker(`veYA5`, EETypes.questCollectible, [179.37100229248503, 228.78204497293126], `Fryer Cage\nFound in Burger Town. Used to repair the teleporter and get to the basement.`),
		new MiscMarker(`Ja2jc`, EETypes.questCollectible, [423.88068477781434, 127.8243562109435], `Polymorphic Crystal Core\nWill spawn after pressing the shutter button, after round 8-9. When interacted with it will spawn a large wave of zombies, fend them off to allow it to be picked up.\nUsed to build the wonder weapon quest, also counts as an intel item.`),
		new MiscMarker(`XFd3U`, EETypes.questCollectible, [383.1791506219179, 177.57780040015749], `Tempered Crystal Heart\nDropped by a tormentor, only when killed using a flamethrower.\nUsed to build the wonder weapon quest, also counts as an intel item.`),
		new MiscMarker(`pKjFl`, EETypes.questCollectible, [245.7535763129745, 264.45501216733527], `Broken Teleporter\nMust be repaired in order to get to the basement and enable power.`),
		new MiscMarker(`ae_ve`, EETypes.mainQuest, [236.66438512209962, 331.39708807762185], `Button For Shutters\nPress to begin the easter egg and enter the first cutscene.`),
		new MiscMarker(`Jvfzd`, EETypes.questCollectible, [221.49957076603778, 264.8502010445337], `Fuel Tank\nObtained by interacting with one of the large white fuel tanks and completing the lockdown.`),
		new MiscMarker(`RV0mf`, EETypes.questCollectible, [113.2661885050118, 164.47601941465607], `Monitoring Device\nDrops from a hole in the wall in the TV Store after using the Activision Grand Prix arcade machine.`),
		new MiscMarker(`ldDz3`, EETypes.forsakenAetherCrystals, [271.9707732304297, 91.92974890168614], `Large Dark Aether Crystal #2\nSpawns 3 floating orbs that must be shot before the main crystal can be destroyed with the Crysalax Savager.\nThis will drop a Crystal Shard. These must be thrown in the mouth of an abomination, turning it's tail orange. Then it must be killed to obtain a Catalyzed Crystal Shard. Do this for all 3 crystals around the map.`),
		new MiscMarker(`MUexS`, EETypes.forsakenAetherCrystals, [350.61973067962373, 191.0702416911157], `Large Dark Aether Crystal #3\nSpawns 3 floating orbs that must be shot before the main crystal can be destroyed with the Crysalax Savager.\nThis will drop a Crystal Shard. These must be thrown in the mouth of an abomination, turning it's tail orange. Then it must be killed to obtain a Catalyzed Crystal Shard. Do this for all 3 crystals around the map.`),
		new MiscMarker(`JCpmh`, EETypes.mainQuest, [383.32564714876884, 269.6057051613007], `Aetherium Neutralizer\nUsed to trigger the start of the boss battle. Once constructed will prompt you to Activate it. When activated it will start an escort mission through the tunnel ahead. Collect orange crystals along the way to fuel the Neutralizer. Once you reach the end power up stations will spawn, before you begin the boss fight. `),
		new MiscMarker(`8JYiv`, EETypes.mainQuest, [107.80007127817684, 410.8341478679704], `The Forsaken\nBoss battle spawn location.`),
	],
	[MapIds.forsakenUnderground]: [
		new MiscMarker(`BhVQV`, EETypes.mainQuest, [296.8934702338299, 46.20376039078092], `Abomination Spawn\nWill spawn upon the first time approaching the portal, must be defeated to lift lockdown and enable power.`),
		new MiscMarker(`mVUTb`, EETypes.questCollectible, [186.7283472806809, 448.0908425556309], `Energetic Geode\nCan be dropped from any dark aether crystal, only when it is destroyed by the electrical beam of an abomination.\nUsed to build the wonder weapon quest, also counts as an intel item.`),
		new MiscMarker(`BjO0P`, EETypes.mainQuest, [407.98348792558136, 223.89943180145679], `Chrysalax Savager\nIs crafted inside the water tank using the 3 required components, after placing the items you must get melee kills near the tank in order to charge/craft the weapon.`),
		new MiscMarker(`rN5Pg`, EETypes.questCollectible, [220.32935092146133, 373.6915310551705], `Housing Unit\nDrops from the wall when charged at by an abomination. Used as part of constructing the Aetherium Neutralizer`),
		new MiscMarker(`mVIvK`, EETypes.forsakenAetherCrystals, [296.373367239989, 339.2622775270375], `Large Dark Aether Crystal #1\nSpawns 3 floating orbs that must be shot before the main crystal can be destroyed with the Crysalax Savager.\nThis will drop a Crystal Shard. These must be thrown in the mouth of an abomination, turning it's tail orange. Then it must be killed to obtain a Catalyzed Crystal Shard. Do this for all 3 crystals around the map.`),

	],
	/////////////////////Outbreak Items/////////////////////////
	[MapIds.zoo]: [
		new MiscMarker(`tzc4_`, EETypes.dementedEcho, [163.36993130570124, 296.445005309222]),
		new MiscMarker(`cl6IT`, EETypes.dementedEcho, [203.0904837783254, 333.7537888538516]),
		new MiscMarker(`AuC64`, EETypes.dementedEcho, [211.47346160666785, 323]),
		new MiscMarker(`s9ryH`, EETypes.dementedEcho, [213.7534729131719, 373.50719403987466]),
		new MiscMarker(`a0AnD`, EETypes.dementedEcho, [116.9816405037569, 387.408120242332]),
		new MiscMarker(`avNJX`, EETypes.aetherialOrb, [350.0944802125016, 341.88195044411935]),
		// TODO: Add steps 2 and 3 of aetherial orb movement steps and draw lines between them on the map.
		new MiscMarker(`33WZV`, EETypes.aetherialOrb, [327.83519665477627, 266.9414020277552]),
		new MiscMarker(`Dg5oV`, EETypes.aetherialOrb, [206.24902262075253, 214.86737932979338], `On top of the gas station roof, in the middle of the circular sign.`),
		new MiscMarker(`DwFPn`, EETypes.aetherialOrb, [198.63857629383395, 282.6457038605504], `Inside the green rail walkway.`),
		new MiscMarker(`Td7Ez`, EETypes.aetherialOrb, [137.78125, 371.03125], `Inside the prison cell.`),
		new MiscMarker(`RxA82`, EETypes.unknownSignal, [377.93564104802994, 370.7222945155921]),
		new MiscMarker(`1j__k`, EETypes.unknownSignalAmp, [357.82570526062756, 355.42125641648164]),
		new MiscMarker(`QdAzj`, EETypes.unknownSignalAmp, [389.3021264930835, 389.95788526875964]),
		new MiscMarker(`9uURg`, EETypes.unknownSignalAmp, [420.77854772553945, 359.7929815876561]),
		new MiscMarker(`NP9oR`, EETypes.unknownSignal, [238.29262198149303, 129.0956431457546]),
		new MiscMarker(`29Qp7`, EETypes.unknownSignalAmp, [264.5785088853629, 115.2157122105336]),
		new MiscMarker(`GCkDK`, EETypes.unknownSignalAmp, [256.59447781643047, 171.7180859291324]),
		new MiscMarker(`7NQwS`, EETypes.unknownSignalAmp, [217.90263494391172, 126.14769321261032]),
		new MiscMarker(`6YPvC`, EETypes.unknownSignal, [135.51866728917045, 369.0881874999369], `On the roof.`),
		new MiscMarker(`1kkfP`, EETypes.unknownSignalAmp, [123.58335545433826, 342.0448860007602]),
		new MiscMarker(`omF_y`, EETypes.unknownSignalAmp, [124.48983483419893, 362.89391173755564]),
		new MiscMarker(`wUSx0`, EETypes.unknownSignalAmp, [101.22353075110836, 383.59185757770763]),
		new MiscMarker(`G6f1o`, EETypes.monkey, [357.6836499712147, 349.54995004995004], `Midway up the stairs in the book nook.`),
		new MiscMarker(`dkJOS`, EETypes.monkey, [334.6704087507196, 262.5942934616404], `In the corner on a green cabinet.`),
		new MiscMarker(`AqCfQ`, EETypes.monkey, [204.52540299366723, 279.51968694570735], `Between a wall and a rock.`),
		new MiscMarker(`t1Csu`, EETypes.monkey, [266.54015544041454, 117.57161206140799], `In the middle of the large shelves.`),
		new MiscMarker(`4uYKW`, EETypes.maxisQuestRadio, [191.73889214493497, 311.0023178810189]),
		new MiscMarker(`EJH8M`, EETypes.maxisAmp, [207.95152974316207, 301.11136617864145], `Behind the counter.`),
		new MiscMarker(`dJZK6`, EETypes.maxisAmp, [200.05547677172723, 325.69574229352554]),
		new MiscMarker(`zNOoG`, EETypes.maxisAmp, [188.1875, 328.0625], `Up the stairs, on the concrete bags.`),
		new MiscMarker(`a84zo`, EETypes.projector, [241.65018710420264, 295.65660105200925]),
		new MiscMarker(`QDny7`, EETypes.redRift, [194.5625, 399.875]),
		new MiscMarker(`41iYu`, EETypes.bonus, [307.9623739662823, 450.6289780715707], `Ritual Site / The Pact Altar\nInteract with the alter to provide a reward based on your completion of the main quests in other maps. The obelisks will indicate which you have completed in order of release left to right. The reward will increase your starting weapon rarity permanently.`),
	],
	[MapIds.duga]: [
		new MiscMarker(`BcM6G`, EETypes.dementedEcho, [218.2373426139386, 258]),
		new MiscMarker(`X69Ma`, EETypes.dementedEcho, [168.83660953980305, 352.95720859235564]),
		new MiscMarker(`M6PhI`, EETypes.dementedEcho, [400.31381858522724, 250.95835630001685], `Inside the underground bunker`),
		new MiscMarker(`ZeAyZ`, EETypes.dementedEcho, [147.2673857661874, 265.46200912494885], `Inside the underground bunker`),
		new MiscMarker(`UtKcD`, EETypes.dementedEcho, [104.0667719037023, 306.8175516471223]),
		new MiscMarker(`xeydw`, EETypes.dementedEcho, [256.02458104515534, 191.20823141347046]),
		new MiscMarker(`xCH_G`, EETypes.dementedEcho, [361.7255340221552, 79.6459891424929]),
		new MiscMarker(`X3Me0`, EETypes.aetherialOrb, [386.53125, 287.125], `On the roof.`),
		new MiscMarker(`fs3If`, EETypes.aetherialOrb, [270.05608471024027, 443.38948547967425]),
		new MiscMarker(`piNyx`, EETypes.aetherialOrb, [117.21875, 318.09375]),
		new MiscMarker(`nAWMN`, EETypes.aetherialOrb, [188.85744444838855, 236.65597160044615], `On top of the checkpoint roof.`),
		new MiscMarker(`dSSMf`, EETypes.aetherialOrb, [200.7261531499677, 272.9761903253458], `In the office with the flags and green table, second floor.`),
		new MiscMarker(`6IA1p`, EETypes.electrobolt, [218.94657303732083, 383.23711642835906]),
		new MiscMarker(`P010c`, EETypes.unknownSignal, [122.47897236448067, 293.85384697759383]),
		new MiscMarker(`opqPj`, EETypes.unknownSignalAmp, [133.38725584069223, 266.96588507570397]),
		new MiscMarker(`hhCoX`, EETypes.unknownSignalAmp, [141.13787831063203, 319.6892552107265]),
		new MiscMarker(`guaSk`, EETypes.unknownSignalAmp, [115.11109668317988, 325.71751713179077]),
		new MiscMarker(`T0yu1`, EETypes.unknownSignal, [274.4294474541645, 259.40663600008367]),
		new MiscMarker(`pjT_w`, EETypes.unknownSignalAmp, [250.12502637558788, 233.95397455559004]),
		new MiscMarker(`W3wWO`, EETypes.unknownSignalAmp, [301.3174093560544, 271.7502199336915]),
		new MiscMarker(`cILkr`, EETypes.unknownSignalAmp, [280.5533960723885, 286.96440478209183]),
		new MiscMarker(`KskrY`, EETypes.unknownSignal, [370.2698515732512, 285.9269277205488]),
		new MiscMarker(`TnyeH`, EETypes.unknownSignalAmp, [359.67178784306867, 309.110192130323]),
		new MiscMarker(`3l08H`, EETypes.unknownSignalAmp, [394.55708095491946, 339.4692288574083]),
		new MiscMarker(`86k_u`, EETypes.unknownSignalAmp, [400.51849180314707, 294.8690439928903]),
		new MiscMarker(`uReU5`, EETypes.monkey, [182.73715299184204, 242], `On top of the small building`),
		new MiscMarker(`knmox`, EETypes.monkey, [339.4971340539545, 215.75], `On top of a stack of barrels.`),
		new MiscMarker(`FIUz1`, EETypes.monkey, [358.4786674447592, 360.5], `At the back on top of the bus stop.`),
		new MiscMarker(`P79of`, EETypes.monkey, [107.24119968079447, 330], `Behind the wall`),
		new MiscMarker(`RGgyM`, EETypes.maxisQuestRadio, [329.4916390857576, 444.7086117644694]),
		new MiscMarker(`vwHf6`, EETypes.maxisAmp, [338.5566004481648, 448.38714681008395]),
		new MiscMarker(`x5PtX`, EETypes.maxisAmp, [310.7048351027977, 432.2278678597059]),
		new MiscMarker(`inuqE`, EETypes.maxisAmp, [339.3448579579393, 424.73942151684776]),
		new MiscMarker(`d2US7`, EETypes.projector, [173.2474009132825, 354.5625]),
		new MiscMarker(`ORM0f`, EETypes.redRift, [391.40929445880636, 252.02191837378084], `Underground, in the bunker.`),
		new MiscMarker(`ofN6E`, EETypes.zooMask, [278.5808192193514, 289.4431670345211], `On a bench.`),
	],
	[MapIds.ruka]: [
		new MiscMarker(`18A2Y`, EETypes.dementedEcho, [332.61885317644305, 225.16827101672993]),
		new MiscMarker(`NFpUv`, EETypes.dementedEcho, [146.8961039621756, 299.61206359787775]),
		new MiscMarker(`vi0sy`, EETypes.dementedEcho, [198.3015251501929, 89.86190166016635]),
		new MiscMarker(`JzRzZ`, EETypes.dementedEcho, [324.1631998133326, 339.009553939874]),
		new MiscMarker(`Rek6g`, EETypes.dementedEcho, [282.9017792859132, 270.16920975405776]),
		new MiscMarker(`5mZDo`, EETypes.aetherialOrb, [328.04137462064233, 340.1360436719856]),
		new MiscMarker(`yyP4V`, EETypes.aetherialOrb, [243.34572612410255, 201.22376324513016]),
		new MiscMarker(`aYcG_`, EETypes.aetherialOrb, [242.8125, 349.53125]),
		new MiscMarker(`TnTWH`, EETypes.unknownSignal, [273.3844894026913, 119.79076679152222]),
		new MiscMarker(`aH6T2`, EETypes.unknownSignalAmp, [273.75459475838085, 149.89266905427343]),
		new MiscMarker(`2I5a7`, EETypes.unknownSignalAmp, [269.31333049010607, 113.62234419669615]),
		new MiscMarker(`908YB`, EETypes.unknownSignalAmp, [292.2598625428591, 126.5760316458309]),
		new MiscMarker(`dwmEB`, EETypes.unknownSignal, [254.72755143316522, 260.02676161362564]),
		new MiscMarker(`bm9Jg`, EETypes.unknownSignalAmp, [235.63212164495448, 276.01575784777333]),
		new MiscMarker(`UdjeW`, EETypes.unknownSignalAmp, [230.88110562109344, 247.9664901684399]),
		new MiscMarker(`vdoyD`, EETypes.unknownSignalAmp, [272.90932429371037, 236.9112413436863]),
		new MiscMarker(`YeH4n`, EETypes.thermophasic, [94.0042121017423, 334.3317226785948]),
		new MiscMarker(`X6iHS`, EETypes.monkey, [126.02392962817726, 148], `On the third shelf next to three boxed.`),
		new MiscMarker(`NXZCq`, EETypes.monkey, [312.61036148443907, 345], `Behind some pallets next to the train platform.`),
		new MiscMarker(`FqjzW`, EETypes.monkey, [366.728066736379, 131], `Leaning against the broken down car.`),
		new MiscMarker(`SGa0I`, EETypes.monkey, [238.30978121120768, 448.3125], `At the end of the railroad, under rafters.`),
		new MiscMarker(`BC05H`, EETypes.maxisQuestRadio, [216.7660299006794, 90.02961020357569]),
		new MiscMarker(`GvD6O`, EETypes.maxisAmp, [242.79564351743252, 88.82921540086134]),
		new MiscMarker(`2ebQM`, EETypes.maxisAmp, [229.78083670905596, 106.70878009392233]),
		new MiscMarker(`IBUd1`, EETypes.maxisAmp, [194.6534940612047, 89.46100213913205]),
		new MiscMarker(`H953x`, EETypes.secretArea, [166.0827125211619, 188.495466417831], `Elevator to the underground silo as part of the first main outbreak quest. Unlocks after completing the monkey microfilm and projector step and teleporting to Ruka on or after round 5.`),
		new MiscMarker(`NuoPB`, EETypes.mainQuest, [162.21311576252214, 199.5317561060424], `Legion Boss Fight\nCan only be started after accessing the missile silo, collecting 3 keys and triggering the launch sequence in the right order.`),
		new MiscMarker(`qRTu_`, EETypes.mainQuest, [186.37934131448654, 184.56152788801134], `Silo A\nApproximate Location\nContains the essence trap used to capture the monkey for one of the ICBM keys. Also has a launch control board, the green lights indicate the order of activation after acquiring the keys.`),
		new MiscMarker(`grcbU`, EETypes.mainQuest, [172.69227551514388, 219.84849440194168], `Silo B\nApproximate Location\nContains the red aetherium canister, can be charged by shooting and collecting crystal shard and depositing them. After charging, will need to be taken to Silo D. Also has a launch control board, the green lights indicate the order of activation after acquiring the keys.`),
		new MiscMarker(`2GHxO`, EETypes.mainQuest, [137.72609960588565, 209.4762648508773], `Silo D\nApproximate Location\n Contains the jellyfish that holds a key, only accessible whilst using the charged aetherium canister from Silo B, remember to grab the key whilst being dragged through the jellyfish. Also has a launch control board, the green lights indicate the order of activation after acquiring the keys.`),
		new MiscMarker(`Fhzza`, EETypes.mainQuest, [152.80325802547407, 191.6189211907974], `Central Silo Room\nApproximate Location\nSpawn location of the red mimic that must be killed to drop one of the 3 ICBM keys.`),
		new MiscMarker(`KDAvD`, EETypes.redRift, [244.73564018442983, 88.5]),
		new MiscMarker(`PfUQA`, EETypes.zooMask, [246.43373481049682, 222.82413429978607], `On a blue barrel.`),
	],
	[MapIds.alpine]: [
		new MiscMarker(`yfDA4`, EETypes.dementedEcho, [335.2545905360252, 70.87629629055847]),
		new MiscMarker(`JnfGP`, EETypes.dementedEcho, [240.22404548274855, 373.19860378733625]),
		new MiscMarker(`BXDDX`, EETypes.dementedEcho, [367.45739492817876, 227.5]),
		new MiscMarker(`sKxXQ`, EETypes.dementedEcho, [282.063145684509, 290.79367162236287]),
		new MiscMarker(`aGYxW`, EETypes.dementedEcho, [211.5378017806543, 142.50036019533616]),
		new MiscMarker(`jWuD8`, EETypes.aetherialOrb, [295.0187358378346, 144.26938180233338]),
		new MiscMarker(`sJ9S8`, EETypes.aetherialOrb, [235.4375, 401.5]),
		new MiscMarker(`Crgf2`, EETypes.aetherialOrb, [146.62596976081815, 273.8331450619502]),
		new MiscMarker(`zAfie`, EETypes.aetherialOrb, [279.47416576150295, 291.08250093388114]),
		new MiscMarker(`3GAvp`, EETypes.aetherialOrb, [334.9574702605456, 343.89150208940714]),
		new MiscMarker(`d1atj`, EETypes.unknownSignal, [374.29160575898163, 235.69436209180503]),
		new MiscMarker(`0d7wk`, EETypes.unknownSignalAmp, [370.99794502544603, 248.07852644989876]),
		new MiscMarker(`ynbO_`, EETypes.unknownSignalAmp, [365.4645949931063, 212.24349766903183]),
		new MiscMarker(`kVeQB`, EETypes.unknownSignalAmp, [405.2096889927135, 215.92319810760293]),
		new MiscMarker(`pvOww`, EETypes.unknownSignal, [307.22437744909496, 93.367408459139]),
		new MiscMarker(`k1c42`, EETypes.unknownSignalAmp, [331.4663009821876, 100.56797980560216]),
		new MiscMarker(`qaPzE`, EETypes.unknownSignalAmp, [285.9826919770286, 97.92777031189901]),
		new MiscMarker(`BRkq_`, EETypes.unknownSignalAmp, [315.86506306485074, 72.96578964416004]),
		new MiscMarker(`zEZ1J`, EETypes.unknownSignal, [138.7423061920885, 328.5255775818504]),
		new MiscMarker(`ELO34`, EETypes.unknownSignalAmp, [160.54949105829024, 349.32004488460626]),
		new MiscMarker(`eI9WF`, EETypes.unknownSignalAmp, [137.32450160326422, 351.27796550726833]),
		new MiscMarker(`lQWrl`, EETypes.unknownSignalAmp, [143.60335049662882, 309.41897288483773]),
		new MiscMarker(`KfCN7`, EETypes.cryoemitter, [106.2932488669672, 313.34003330490333]),
		new MiscMarker(`EXt18`, EETypes.monkey, [256.4818629189573, 69.25], `On the balcony against the wall.`),
		new MiscMarker(`VPl2_`, EETypes.monkey, [138.24444493704556, 195], `Upstairs on a shelf.`),
		new MiscMarker(`VcAW0`, EETypes.monkey, [129.99532275226102, 339.5], `On top of some wall mounted cases.`),
		new MiscMarker(`aFKUh`, EETypes.monkey, [409.2206641248448, 212.25], `Behind the porta-potties, by the gasoline tank.`),
		new MiscMarker(`VoB7V`, EETypes.maxisQuestRadio, [206.42256828945733, 323.91253771346766]),
		new MiscMarker(`9njmA`, EETypes.maxisAmp, [215.19215452397677, 342.01386314625773]),
		new MiscMarker(`ewKJj`, EETypes.maxisAmp, [171.7939457223806, 300.8642661996666]),
		new MiscMarker(`51Np2`, EETypes.maxisAmp, [224.07417135124643, 326.3860107539731]),
		new MiscMarker(`rYPHj`, EETypes.projector, [199.38751401269195, 138.7419183294555]),
		new MiscMarker(`43cui`, EETypes.redRift, [158.8271253455655, 253.75958752419623]),
		new MiscMarker(`Z7ZZT`, EETypes.zooMask, [252.94159647833757, 223.5016466331229], `In the snow, beside a red and yellow pillar.`),
	],
	[MapIds.golova]: [
		new MiscMarker(`_9qob`, EETypes.dementedEcho, [167.02309933760583, 326.8300791718768]),
		new MiscMarker(`1blw4`, EETypes.dementedEcho, [184.7468020757672, 156.32421714103378]),
		new MiscMarker(`qAgVa`, EETypes.dementedEcho, [190.00365176294605, 397.6050741155826]),
		new MiscMarker(`I5d6u`, EETypes.dementedEcho, [91.33402582482371, 335.3619366422391]),
		new MiscMarker(`Sjjf7`, EETypes.dementedEcho, [331.2666015762386, 282.2520092653466]),
		new MiscMarker(`NMtT4`, EETypes.dementedEcho, [136.33052041417935, 278.97388266034534]),
		new MiscMarker(`Uevdm`, EETypes.aetherialOrb, [334.64974840518437, 349.32449239453473]),
		new MiscMarker(`YnzHx`, EETypes.aetherialOrb, [100.0357091478215, 336.9160415489129]),
		new MiscMarker(`1CIV3`, EETypes.aetherialOrb, [234.53164273202665, 283.2184602667901]),
		new MiscMarker(`DHIpJ`, EETypes.aetherialOrb, [295.4188442387635, 275.8291991501066]),
		new MiscMarker(`Iyl71`, EETypes.aetherialOrb, [229.32359347121474, 361.107903622541]),
		new MiscMarker(`ebdmf`, EETypes.unknownSignal, [189.71875, 335.46875]),
		new MiscMarker(`85DV2`, EETypes.unknownSignalAmp, [198.03201168380622, 331.2535468165486]),
		new MiscMarker(`972aa`, EETypes.unknownSignalAmp, [199.7333856103604, 358.47552964141545]),
		new MiscMarker(`ybspF`, EETypes.unknownSignalAmp, [164.44508450219828, 338.9342482397485]),
		new MiscMarker(`eBdU3`, EETypes.unknownSignal, [290.22549276450945, 141.9315225998444]),
		new MiscMarker(`ldZ3K`, EETypes.unknownSignalAmp, [278.0406202555562, 123.14023462220023]),
		new MiscMarker(`GHxiq`, EETypes.unknownSignalAmp, [313.5304359327235, 146.22229073634261]),
		new MiscMarker(`LbRSO`, EETypes.unknownSignalAmp, [273.8728096598053, 155.46762692266708]),
		new MiscMarker(`jxkej`, EETypes.nova5, [197.8850734145944, 146.29544698855156]),
		new MiscMarker(`QkQhl`, EETypes.monkey, [179.47691966660756, 151], `In the elevated building behind a chain link fence.`),
		new MiscMarker(`mk0gS`, EETypes.monkey, [351.4586185493882, 227], `Up in the rafters.`),
		new MiscMarker(`xsRN7`, EETypes.monkey, [106.49109327895019, 339.5], `On a pillar at the top of the scaffolding.`),
		new MiscMarker(`8xhDt`, EETypes.monkey, [185.48268753325056, 416.75], `On the top shelf.`),
		new MiscMarker(`hqWBb`, EETypes.maxisQuestRadio, [363.24480398310953, 314.1474017192487]),
		new MiscMarker(`ejgH2`, EETypes.maxisAmp, [360.01596128103745, 299.9974734072269]),
		new MiscMarker(`ESg7F`, EETypes.maxisAmp, [346.24589681631824, 345.3912031598874]),
		new MiscMarker(`UfSJA`, EETypes.maxisAmp, [386.2265667449167, 326.8728406038857]),
		new MiscMarker(`iMHwr`, EETypes.projector, [177.0071341819346, 347.625]),
		new MiscMarker(`oI3EF`, EETypes.redRift, [92.49637125376842, 331.625]),
		new MiscMarker(`lzG2p`, EETypes.zooMask, [186.875, 312.03125], `In the grass in front of the statue.`),
	],
	[MapIds.sanatorium]: [
		new MiscMarker(`ijcwV`, EETypes.dementedEcho, [121.50275687461007, 277.77812593764446]),
		new MiscMarker(`FpAjy`, EETypes.dementedEcho, [168.9897871582542, 435.97930944364305]),
		new MiscMarker(`H8LVB`, EETypes.dementedEcho, [302.18576429912486, 307.75772061687144]),
		new MiscMarker(`U0DjB`, EETypes.dementedEcho, [289.0557298906468, 211.186559738963]),
		new MiscMarker(`rngCc`, EETypes.dementedEcho, [284.82361164993495, 58.75987297871505]),
		new MiscMarker(`mZ5AD`, EETypes.aetherialOrb, [359.5562619363169, 138.68768084911696]),
		new MiscMarker(`5u7Uw`, EETypes.aetherialOrb, [266.5096929330548, 199.49767362940744]),
		new MiscMarker(`rw6TZ`, EETypes.aetherialOrb, [234.44976450587362, 154.45378186607422]),
		new MiscMarker(`_JnnW`, EETypes.aetherialOrb, [152.52312850546937, 122.19272444648973]),
		new MiscMarker(`DRDhc`, EETypes.aetherialOrb, [152.76378076668848, 382.2723112534592]),
		new MiscMarker(`aQ1dT`, EETypes.unknownSignal, [315.79619113139, 213.16572267442177]),
		new MiscMarker(`L6vko`, EETypes.unknownSignalAmp, [327.2581304840937, 248.07852644989876]),
		new MiscMarker(`kyPMi`, EETypes.unknownSignalAmp, [281.41037307327866, 203.67997976183935], `On a desk inside the offices.`),
		new MiscMarker(`UBPJW`, EETypes.unknownSignalAmp, [345.1756448745272, 191.82280112111133]),
		new MiscMarker(`dnojN`, EETypes.unknownSignal, [146.0481425873068, 422.3500358873178]),
		new MiscMarker(`r5JyD`, EETypes.unknownSignalAmp, [169.33987237706896, 422.45000039285327]),
		new MiscMarker(`V2Hv_`, EETypes.unknownSignalAmp, [150.0467228087252, 374.96686026350983]),
		new MiscMarker(`DR4n4`, EETypes.unknownSignalAmp, [126.55506400789214, 447.0412687545764]),
		new MiscMarker(`xpCIP`, EETypes.monkey, [317.4583525447774, 209.5], `In the Admins office on a bookshelf floor 2.`),
		new MiscMarker(`nOKZv`, EETypes.monkey, [129.74498802979247, 278.375], `Up in the rafters.`),
		new MiscMarker(`BDOca`, EETypes.monkey, [265.106262191878, 348], `Underneath the bridge, on a support pillar.`),
		new MiscMarker(`bEmlA`, EETypes.monkey, [194.48468256783116, 67.75], `On the top shelf above the bed.`),
		new MiscMarker(`4hUUQ`, EETypes.maxisQuestRadio, [259.0957413648168, 187.83019728788105]),
		new MiscMarker(`PtQYU`, EETypes.maxisAmp, [246.08093455644018, 197.18064101428752]),
		new MiscMarker(`s96qD`, EETypes.maxisAmp, [277.228020753186, 221.88350248067215]),
		new MiscMarker(`8Pa9A`, EETypes.maxisAmp, [269.3306865248022, 163.8223012335942]),
		new MiscMarker(`ZO8MP`, EETypes.projector, [364.45335165809536, 179.5]),
		new MiscMarker(`SFmVd`, OutbreakEE2Steps.step2Helicopter, [178.2582145549112, 78.59647195866988]),
		new MiscMarker(`g0Vbn`, OutbreakEE2Steps.step3Orb, [128.51585279728454, 269.42304126372875], `Can spawn in 1 of 3 locations, this one is located on the roof`),
		new MiscMarker(`ecy3g`, OutbreakEE2Steps.step3Orb, [393.4503192055329, 163.5], `Can spawn in 1 of 3 locations, this one is located on the roof`),
		new MiscMarker(`brKfY`, OutbreakEE2Steps.step3Orb, [264.5128764056229, 197.08519448344717], `Can spawn in 1 of 3 locations, this one is located in the middle of the pool`),
		new MiscMarker(`8pP_f`, EETypes.bunny, [124.24775686224359, 132.53094065305984]),
		new MiscMarker(`nslr3`, EETypes.bunny, [246.10022477665626, 218.71196917701238]),
		new MiscMarker(`nH2On`, EETypes.bunny, [335.59375, 150.75]),
		new MiscMarker(`_dw2s`, EETypes.secretArea, [299.70855299802906, 351.714669003909], `Orda boss fight location, the rebuilt rover is required in order to pass through the barrier.`),
		//new MiscMarker(new Item(`oEPK3`, `Chopper Gunner`, `Hold out until the wave is dead to continue with the Easter Egg`), [-170.51300319205535, 39.25])
		new MiscMarker(`3U1Kx`, EETypes.zooMask, [268.0254816847518, 201.69485983672456], `Under the water in the centre of the pool by PaP.`),
	],
	[MapIds.collateral]: [
		new MiscMarker(`LZtAf`, EETypes.dementedEcho, [302.1582217610988, 414.1356865122179]),
		new MiscMarker(`2ogDj`, EETypes.dementedEcho, [196.9588998109597, 319.81699864797247]),
		new MiscMarker(`jZYx5`, EETypes.dementedEcho, [304.4851067015507, 202.60851085028]),
		new MiscMarker(`kEe8z`, EETypes.aetherialOrb, [141.32661710174656, 118.98874482804175]),
		new MiscMarker(`B8gYS`, EETypes.aetherialOrb, [256.51628033942154, 167.22147796251068]),
		new MiscMarker(`PHwrQ`, EETypes.aetherialOrb, [313.3569142959767, 359.19613522868246]),
		new MiscMarker(`Zkss8`, EETypes.aetherialOrb, [253.59375, 451.4375]),
		new MiscMarker(`U1mRn`, EETypes.maxisQuestRadio, [103.80643363061118, 137.17767184651254]),
		new MiscMarker(`RR9UL`, EETypes.maxisAmp, [123.91123698199438, 182.99474070850823]),
		new MiscMarker(`2XQVR`, EETypes.maxisAmp, [87.53111663187241, 98.06220410164455]),
		new MiscMarker(`uJGup`, EETypes.maxisAmp, [153.23943961285346, 139.44430689634171]),
		new MiscMarker(`aKvCm`, EETypes.unknownSignal, [305.84476920435446, 429.33372468749263]),
		new MiscMarker(`H6dJ4`, EETypes.unknownSignalAmp, [342.64987791566955, 448.6244332108942]),
		new MiscMarker(`q7WFL`, EETypes.unknownSignalAmp, [315.53672540433615, 389.695126305816]),
		new MiscMarker(`sdqd4`, EETypes.unknownSignalAmp, [289.20232712604985, 451.3167622400238]),
		new MiscMarker(`quGiV`, EETypes.unknownSignal, [288.9614214809424, 158.0324782067784]),
		new MiscMarker(`crMVB`, EETypes.unknownSignalAmp, [317.22286059580455, 76.72187340893528]),
		new MiscMarker(`ZvTyQ`, EETypes.unknownSignalAmp, [412.572982447416, 206.47161221493477]),
		new MiscMarker(`uQEjW`, EETypes.unknownSignalAmp, [242.96454069561725, 196.30468377190476]),
		new MiscMarker(`luWBb`, EETypes.monkey, [327.65625, 464.46875], `On a windowsill outside.`),
		new MiscMarker(`xTY5J`, EETypes.monkey, [145.3085984678254, 330.7769433572229], `Inside some broken pipe.`),
		new MiscMarker(`WynRr`, EETypes.monkey, [142.10038624739008, 193.20451003781437], `By the feet of the silos.`),
		new MiscMarker(`NLT6K`, EETypes.monkey, [290.6490258001013, 166.6555049819262]),
		new MiscMarker(`T42U3`, EETypes.projector, [276.52649758435535, 255.64213181187142]),
		new MiscMarker(`XLltd`, EETypes.redRift, [268.485236370739, 177.6590598896251]),
		new MiscMarker(`HKJYL`, EETypes.zooMask, [253.2459340007104, 277.5226580773655], `On a bucket, holding the door open.`),
	],
	[MapIds.armada]: [
		new MiscMarker(`AEpO7`, EETypes.dementedEcho, [245.08327415625956, 200.03583430491622]),
		new MiscMarker(`eKuk9`, EETypes.dementedEcho, [191.30383357596716, 134.46987913495167]),
		new MiscMarker(`l9CCu`, EETypes.dementedEcho, [300.8856411583173, 350.8475161407786]),
		new MiscMarker(`N_jXt`, EETypes.aetherialOrb, [311.1126069881438, 184.77455097576265]),
		new MiscMarker(`ACjLt`, EETypes.aetherialOrb, [260.1864362013941, 124.3833141033948], `Underwater, in the corner of the capsized boat. `),
		new MiscMarker(`h4HUS`, EETypes.aetherialOrb, [201.50633494672996, 388.85962652554787]),
		new MiscMarker(`V3lB8`, EETypes.maxisQuestRadio, [190.56726082261702, 140.05125932484833]),
		new MiscMarker(`QKe5Z`, EETypes.maxisAmp, [193.87692988626395, 149.98026651578908]),
		new MiscMarker(`3DpDG`, EETypes.maxisAmp, [187.2575917589701, 179.9414811972243]),
		new MiscMarker(`vbxKd`, EETypes.maxisAmp, [196.83821273268487, 64.62564329542131]),
		new MiscMarker(`Os6pL`, EETypes.unknownSignal, [261.9257906505539, 214.6789938332886]),
		new MiscMarker(`Y9GNs`, EETypes.unknownSignalAmp, [237.10489278341913, 265.0465468151352]),
		new MiscMarker(`grgkn`, EETypes.unknownSignalAmp, [288.1409254098644, 91.56966208677449]),
		new MiscMarker(`uUbza`, EETypes.unknownSignalAmp, [338.7233304224225, 286.7865934912584]),
		new MiscMarker(`ZppLw`, EETypes.unknownSignal, [200.86153471581986, 319.81675295943194]),
		new MiscMarker(`nJFbl`, EETypes.unknownSignalAmp, [197.20277672061397, 258.6536419816775]),
		new MiscMarker(`7E5gL`, EETypes.unknownSignalAmp, [228.20696255778262, 410.3068041089928]),
		new MiscMarker(`I7fi0`, EETypes.unknownSignalAmp, [251.18951176176026, 295.2778263261625]),
		new MiscMarker(`pYrMn`, EETypes.monkey, [313.2485303432156, 359.03100785491637], `Chilling on the chair.`),
		new MiscMarker(`8F7tJ`, EETypes.monkey, [181.15841579988762, 144.79529969011574], `Second Floor, in the corner.`),
		new MiscMarker(`WIJtD`, EETypes.monkey, [251.23276211928132, 316.3504914936959], `Between two control boards on the floor.`),
		new MiscMarker(`qk9uh`, EETypes.monkey, [228.8863956945445, 244.4522948695135], `Hiding behind the curtain.`),
		new MiscMarker(`SaBpE`, EETypes.projector, [322.80314185151997, 353.37968827572143]),
		new MiscMarker(`gLRQZ`, EETypes.redRift, [281.5872778794272, 99.5338716371389], `Underwater, used to start the main quest.`),
		new MiscMarker(`6OweP`, EETypes.zooMask, [251.06749530113694, 258.1062871710513], `On the desk inside the ship, opposite the box.`),
	]
	/////////////////////Bo6 Items/////////////////////////
};